import request from "../plugins/axios";

export function lossContact(params) {
    return request({
        url: '/workContact/lossContact',
        method: 'GET',
        params
    })
}
export function deleteContact(params) {
    return request({
        url: '/workContact/deleteContact',
        method: 'GET',
        params
    })
}

// 流失客户、删除客户列表
export function departmentLossContact(params) {
    return request({
        url: '/workContact/departmentLossContact',
        method: 'GET',
        params
    })
}