<template>
  <div class="index">
    <van-nav-bar
      title="流失客户"
      left-arrow
      @click-left="back"
    />
    <div class="content" style="margin-bottom: 60px">
      <div v-for="(i,is) in list" :key="is" class="list">
        <img style="margin-top: -5px;" :src="i.avatar">
        <div style="position: relative; border-bottom: 1px solid #eee; padding-bottom: 12px;">
          <div style="color: #444; font-size: 15px;">{{i.name}}</div>
          <div style="color: #9b9b9b;">流失时间：{{ i.createTime }}</div>
          <span @click="pushUser(i.id)">查看客戶</span>
        </div>
      </div>
      <div class="loading" v-if="loading">
        <a-spin/>
      </div>
      <van-empty v-else-if="list.length == 0"  description="暂无数据" style="margin-top: 40px"/>
    </div>
  </div>
</template>

<script>
import {lossContact} from '../../api/lostCustomers'
import { getCookie, pushUser } from '@/plugins/utils'
import { openUserProfile } from '@/utils/wxCodeAuth'

export default {
  data() {
    return {
      pushUser,
      loading: true,
      list: []
    }
  },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    back(){
      this.$router.back()
    },
    seeContactDetail (data) {
      openUserProfile(2, data.wxExternalUserid)
    },
    // pushUser(item) {
    //   var url = 'https://work.' + location.hostname.split(".")[1] + '.' + location.hostname.split(".")[2] + '/contact?agentId=' + getCookie('workbench_agentId') + '&contactId='+item.id
    //   window.location.href = url
    // },
    getData(){
      this.loading = true
      lossContact().then(res=>{
        this.list = res.data
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-dropdown-menu__bar {
  height: 36px;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}

/deep/ .tabs .van-cell__title {
  width: 56px !important;
  flex: none !important;
  white-space: nowrap;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 15px;
  }

  .fs13 {
    font-size: 12px;
  }

  .fs14 {
    font-size: 14px;
  }

  .loading {
    width: 100%;
    height: 30vh;
    /* background: #fff; */
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .header-tool {
    padding: 0 18.2347px;
    height: 40px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background-color: #fff;
    .clickable{
      font-size: 12px;
    }
    .title {
      font-size: 14px;
      margin: 0 !important;
    }

    .select-all {
      font-size: 12px;
      color: #1890ff;
    }
  }

  .content {
    background: #fff;
    min-height: 92%;
    img {
      width: 22px;
      height: 22px;
    }
    .list{
      background: #fff;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img{
        width: 48px;
        height: 48px;
        border-radius: 4px;
      }
      div{
        flex: 1;
        margin-left: 5px;
        display: flex;
        /* align-items: center; */
        flex-direction: column;
        justify-content: space-between;
        span{
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: #ebf7ff;
          border: 1px solid #daedff;
          color: #0091ff;
          padding: 1px 2px;
        }
      }
    }
  }
}
</style>
